@import '~antd/dist/antd.css';
@import './theme/colors.css';
@import './theme/fonts.css';

body{
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  /* margin-bottom: env(safe-area-inset-bottom); */
  height: calc(100% - constant(safe-area-inset-top));
  height: calc(100% - env(safe-area-inset-top) /* - env(safe-area-inset-bottom) */);
  background-color: var(--base-color); 
}

Ion-App {
  text-align: center;
  background-color: var(--base-background); 
}

main {
  height: 100%;
  padding: 1rem;
}

ion-content ion-toolbar {
  --background: translucent;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--base-color); 
  font-family: var(--header-font);
}

* {
  font-family: var(--body-font);
}

.ant-checkbox + span {
  margin-left: 3rem;
}

.checkbox-form {
  text-align: left;
}

.fill-height-or-more {
  min-height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 20px;
} 

.fill-height-or-more > * {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.fill-height-or-more :first-child {
  justify-content: flex-start;
}

ion-tab-bar {
  background-color: #7d02f9;
  border-radius: 20px;
  height: 50px;
  padding: 4px;
  margin: 10px;
}

ion-tab-button {
  color: #FFF;
  background: none;
}

img.bearIcon  {
  content:url("/assets/icon/menu_bear_white.svg");
  color: #fff;
}

.tab-selected > img.bearIcon {
  content:url("/assets/icon/menu_bear_yellow.svg");
  color: #ffcc2f;
}

img.habitIcon  {
  content:url("/assets/icon/menu_tick_white.svg");
  color: #fff;
}

.tab-selected > img.habitIcon {
  content:url("/assets/icon/menu_tick_yellow.svg");
  color: #ffcc2f;
}

img.bankIcon  {
  content:url("/assets/icon/menu_bank_white.svg");
  color: #fff;
}

.tab-selected > img.bankIcon {
  content:url("/assets/icon/menu_bank_yellow.svg");
  color: #ffcc2f;
}

.tab-bar svg {
  width: 1.5rem;
  height: 1.5rem;
}

.fiftyFifty {
  margin: 0;
  padding: 0;
  height: 90%;
  width: 100%
}

.fiftyFifty > * {
  margin: 0;
  padding: 0;
  height: 50%;
  width: 100%
}

strong {
  display: inline;
}
.comingSoon {
  color: red;
  border-style: solid;
  border-color: red;
  border-width: 4px;
  border-radius: 100px;
  background-color: #FDD;
  padding: 0.5rem;
  margin: auto;
  font-size: 18px;
}
