@import '../../../theme/colors.css';

.clock {
  font-size: 5rem;
  color: var(--base-color); 
}

.clockLow {
  color: red;
}

.target {
  font-size: 4rem;
}

.startPower {
  font-size: 4rem;
  color: red;
}


.stopPower {
  font-size: 4rem;
  color: green;
}
